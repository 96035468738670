import EmailForm from "../../Components/Forms/Contact/EmailForm/EmailForm";
import Map from "../../Components/Forms/Contact/Map/Map";
import HeadData from "../../Components/Head/HeadData";
import Navbar from "../../Components/Navbar/Navbar";
import './BookingStyle.css';

const Booking = ()=>{

    return(
        <>
        <Navbar activePage="Booking"/>
        <HeadData pageName="booking"/>
        <div id="bookingCont">
            <section id="booking">
                <div className="leftSection">
                    <h4>Come Visit Us</h4>
                    <p>Please email for a free consultation and to set your appointment time.</p>
                    <p>Levanas 10B High street HucknallNottingham NG15</p>
                    <p>sirenbeautynottingham@gmail.com</p>
                </div>
                <div className="rightSection">
                    <EmailForm showTitle={false}/>
                </div>
            </section>
            <Map/>
        </div>
        </>

    )
}

export default Booking;