import { useState } from 'react';
import {Helmet} from 'react-helmet';
import ServiceCard from "../../Components/Cards/ServiceCards/ServiceCard";
import PageTitle from "../../Components/Elements/PageTitle";
import Navbar from "../../Components/Navbar/Navbar";
import ServicesData from "../../Data/Services";
import PageMetaData from '../../Data/PageMetaData';
import './ServicesStyle.css';
import HeadData from '../../Components/Head/HeadData';

const Services = ()=>{
    const cards = ServicesData.map((elm)=>{return<ServiceCard key={Math.random()} title={elm.title} price={elm.price} description={elm.description}/>})
    return(
        <>
        <Navbar activePage="Services"/>
        <HeadData pageName='services'/>
        <div className="services">
            <section>
                {/* <h2>Services</h2> */}
                <PageTitle className="" title="Services"/>
                <h5>All treatments include a consultation so we can both understand each other in what can be achieved and discuss what is right for you. </h5>
            </section>
            <article>
                {cards}
            </article>
            <hr></hr>
        </div>
        </>
    )
}
export default Services;