import './InfoSectionStyle.css';
import images from '../../../../Data/Images';
import { useState } from 'react';
const InfoSection = (props:any)=>{
    const list = props.listItems.map((elm:string)=>{return<li>{elm}</li>});
    const imgChose = (imgName : string)=>{
        const out = {
            src : "",
            alt : ""
        }
        switch(imgName){
            case "mail" : 
                out.src = images.icons.mail; 
                out.alt = "mail icon";
                break;
            case "phone" : 
                out.src = images.icons.phone;
                out.alt = "phone icon";
                break;
            case "map" : 
                out.src = images.icons.map; 
                out.alt = "map icon";
                break;
            default : console.log(imgName, " not recognsed");
        }
        return out;
    }
    const [img] = useState(imgChose(props.image));
    return(
        <div className="infoSection">
            <div className="top">
                <span className="imgSpan"><img src={img.src} alt={img.alt}/></span>
                <h5>{props.title}</h5>
            </div>
            <div>
                <ul>{list}</ul>
            </div>
        </div>
    )
}
export default InfoSection;