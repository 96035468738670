import PageTitle from "../../Components/Elements/PageTitle";
import './PortfolioStyle.css';
import Navbar from "../../Components/Navbar/Navbar";
import { Link } from "react-router-dom";
import HeadData from "../../Components/Head/HeadData";
import GalleryContainer from "../../Components/Gallery/GalleryContainer/GalleryContainer";
const Portfolio = ()=>{

    return(
        <>
        <Navbar activePage="Portfolio"/>
        <HeadData pageName="portfolio"/>
        <section id="portfolio">
            <div id="sectionCont">
                <PageTitle className="content" title="Portfolio"/>
                <p className="content">All pigments and tools used are safe, sterile, high end and carefully chosen for the treatments provided.</p>
            </div>
            <hr />
            <GalleryContainer/>
            <div id="sectionBack"></div>

        </section>
        </>
    )
}
export default Portfolio;