import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api"
import './MapStyle.css';
const Map = ()=>{
    const { isLoaded} = useLoadScript({
        googleMapsApiKey: 'AIzaSyCmqR9IEsRmspVDGUq4avWQCxFsektl0js'
    })
    if (!isLoaded) return <div>Loading...</div>;
    return <MapDisplay/>
}
const MapDisplay = ()=>{
    const center = useMemo(()=>({lat:53.04018257056328, lng:-1.206816702594828}), [])
    return (
        <GoogleMap
            zoom={16}
            center = {center}
            mapContainerClassName="mapContainer">
            <MarkerF 
                position={center}
                title="Title"
                label="siren Ink"/>
        </GoogleMap>
    )
}
export default Map;
// 53.04018257056328, -1.206816702594828