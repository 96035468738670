import images from "./Images"
import Gallery from "./ImageData/Gallery"
export type GalleryDataSingle = {
    title : string,
    thumbnail:{
        src : keyof typeof images.gallery.Thumbnails,
        alt : string
    },
    images : {alt:string,header:string,src:string}[]
}
type GalleryDataType = {
    powder : GalleryDataSingle,
    combo : GalleryDataSingle,
    lip     : GalleryDataSingle,
    micro   : GalleryDataSingle,
    remove   : GalleryDataSingle,
    eRemove : GalleryDataSingle,
    getByTitle : (title:string)=>GalleryDataSingle|null
}
const GalleryDataObj : GalleryDataType = {
    powder : {
        title : "Powder/Ombre Brows",
        thumbnail : {
            src : "powder",
            alt : "Powder Brows"
        },
        images : [
            {alt:"Powder/Ombre 1",header:"Powder/Ombre 1",src:Gallery.Powder[0]},
            {alt:"Powder/Ombre 2",header:"Powder/Ombre 2",src:Gallery.Powder[1]},
            {alt:"Powder/Ombre 3",header:"Powder/Ombre 3",src:Gallery.Powder[2]},
            {alt:"Powder/Ombre 4",header:"Powder/Ombre 4",src:Gallery.Powder[3]},
        ]
    },
    combo : {
        title : "Combination Brows",
        thumbnail : {
            src : "combo",
            alt : "Combo Brows"
        },
        images : [
            // Add Images to show in portfolio
        ]
    },
    lip : {
        title : "Lip Blush",
        thumbnail : {
            src : "lipBlush",
            alt : "Lip Blush"
        },
        images : [
            {alt:"Lip Blush 1",header:"Lip Blush 1",src:Gallery.Lip[0]},
            {alt:"Lip Blush 2",header:"Lip Blush 2",src:Gallery.Lip[1]},
            {alt:"Lip Blush 3",header:"Lip Blush 3",src:Gallery.Lip[2]},
        ]
    },
    micro : {
        title : "Microneedling",
        thumbnail : {
            src : "microNeedle",
            alt : "Micro Needling"
        },
        images : [
            // Add Images to show in portfolio
        ]
    },
    remove : {
        title : "Botched Ink Removal",
        thumbnail : {
            src : "removal",
            alt : "Ink Removal"
        },
        images : [
            // Add Images to show in portfolio
        ]
    },
    eRemove : {
        title : "Botched Ink Emergency Removal",
        thumbnail : {
            src : "emergencyRemove",
            alt : "Emergency Ink Removal"
        },
        images : [
            // Add Images to show in portfolio
        ]
    },
    getByTitle : (title:string)=>{
        let out : null|GalleryDataSingle = null;
        for(let data in GalleryDataObj){
            if (title === GalleryDataObj[data].title) out = GalleryDataObj[data];
        }
        if (out === null) new Error(`${title}, not found in GallyDataObj`);
        return out;
    }
}
const GalleryDataArr : GalleryDataSingle[] = [];
for(let i in GalleryDataObj){
    const item = GalleryDataObj[i];
    if ((typeof(item.title) === "string") && (item.images.length > 0)) GalleryDataArr.push(GalleryDataObj[i]);
}
export {GalleryDataObj,GalleryDataArr};