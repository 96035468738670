import './NavStyle.css';
import images from '../../Data/Images';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const emailTarget = "sirenbeautynottingham@gmail.com";
const Navbar = (props:{activePage : string})=>{
    const setLinksInit = ()=>{
        let linksNew = [...links];
        linksNew.forEach(elm =>{
            if (elm.name === props.activePage){
                elm.active = "active";
            }
        })
        setLinks(linksNew);
    }
    useEffect(()=>{
        setLinksInit();
    },[])
    const emailLink = ()=>{
        window.location.href = `mailto:${emailTarget}`;
    }
    const [links,setLinks] = useState([
        {name : "Home",link : "/",img:images.icons.home, active:"",imageAlt : "Home Icon"},
        {name : "Services",link : "/services",img:images.icons.service, active:"",imageAlt : "Services Icon"},
        {name : "Portfolio",link : "/portfolio",img:images.icons.portfolio, active:"",imageAlt : "Portfolio Icon"},
        {name : "Booking",link : "/booking",img:images.icons.booking, active:"",imageAlt : "Booking Icon"},
    ])

    const rightLinks = links.map((elm)=>{return (
        <>
            <Link className={"desktop link " + elm.active} to={elm.link}>
                <li >{elm.name}</li>
            </Link>
            <Link className={'mobile link ' + elm.active} to={elm.link}>
                <li ><img className='imgLink' src={elm.img} alt={elm.imageAlt}/></li>
                <span>{elm.name}</span>
            </Link>
        </>
    )
})
    return(
        <>
            <div id="navOutter">
                <nav className="navBar">
                    <div id="navCont">
                        <div className="leftLinks">
                            <ul>
                                <li onClick={emailLink} className='link desktop'>{emailTarget}</li>
                            </ul>
                        </div>
                        <div className="rightLinks">
                            <ul>
                            {/* <li className='link mobile'><img className='imgLink' src={images.icons.mail}/></li> */}
                            <div className='mobile' onClick={emailLink}>
                                <li><a href='#' className="link"><img className='imgLink' src={images.icons.mail} alt='Email Icon'></img></a></li>
                                <span>Email</span>
                            </div>
                                {rightLinks}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div id="navPush"></div>
        </>
    )
}
export default Navbar;