import ContactForm from '../../Components/Forms/Contact/ContactForm';
import Navbar from '../../Components/Navbar/Navbar';
import './HomeStyle.css';
import ProfileCard from '../../Components/Cards/profileCard/ProfileCard';
import HeadData from '../../Components/Head/HeadData';
const Index = ()=>{
    return (
        <>
        <Navbar activePage='Home'/>
        <HeadData pageName='home'/>
        <div className='sectionOutter'>
            <section>
            <ProfileCard imageAlt='Picture of Emma Shaw'/>
                <div className="mainHeader">
                    <h2 className='font1 header2Size'>Welcome to</h2>
                    <h1 className='font1 header1Size'>Siren Ink</h1>
                </div>
                <h4><i>When art and science combine to create the beauty of permanent make up….</i></h4>
                <p>Welcome to Siren Ink, home of your permanent make up artist. Established in 2020 Siren Ink was set up by Emma to help people fall in love with cosmetic tattoo treatments as she did. Having a passion for giving her clients the best, Emma has trained with multiple talented artists whilst carefully researching and choosing the best methods and products to date. Not only considering how the treatment looks new, but how it will heal and age over time.</p>
            </section>
            <ContactForm/>
        </div>
        </>
    )
}
export default Index;