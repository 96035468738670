type ServiceData = {
    title : string,
    price : string,
    description : string,
}

const POWDER : ServiceData = {
    title : "Powder/Ombre Brows",
    price : "£210 per session",
    description : "Pigment is implanted into the brows using a dusting technique with a machine. This creates a soft airy powdery look that can be enjoyed for 18/24 months."
};
const COMBO : ServiceData = {
    title : "Combination Brows",
    price : "£245 per session",
    description : "The best of both worlds. Strokes and shading are carefully placed together for a super natural but full look. Results can be expected to last 18/24 months."
};
const LIPBLUSH : ServiceData = {
    title : "Lip Blush",
    price : "£210 per session",
    description : "Skin is packed with pigment to enhance Colour for the lips. Perfectly compliments fillers for extra wow factor. Results can be expected to last upto 3 years."
};
const MICRONEEDLING : ServiceData = {
    title : "Microneedling",
    price : "£45 per session or 3 sessions for £115",
    description : "This treatment results in new fresh glowing skin. Creating tiny controlled injuries in the skin allows the healing process to take form. As this happens the skin is rejuvenated with new skin cells and makes the face appear more youthful. A series of treaments are reccomended for best results."
};
const BOTCHEDINKREMOVAL : ServiceData = {
    title : "Botched Ink removal",
    price : "£75 per session",
    description : `To remove old or unwanted permanent make up. "Because you wouldn't put today's makeup over yesterdays." A specialised solution is tattooed into the skin to cause an osmotic reaction to lift and fade unwanted pigment. Emergency removals have to to done within 48 hours of treatment.`
}
const BOTCHEDINKREMOVALEM : ServiceData = {
    title : "Botched Ink emergency removal",
    price : "£150 per session",
    description : `Must be done within 48 hours to remove fresh ink. DIY must not be attempted beforehand.`
}
const ServicesData : ServiceData[] = [
    POWDER,
    COMBO,
    LIPBLUSH,
    MICRONEEDLING,
    BOTCHEDINKREMOVAL,
    BOTCHEDINKREMOVALEM
]
export default ServicesData;