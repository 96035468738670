import { useState,useReducer } from "react";
import {GalleryDataArr,GalleryDataObj, GalleryDataSingle} from "../../../Data/GalleryData";
import GalleryImgContainer from "./GalleryImgContainer/GalleryImgContainer";
import GalleryOpenImage from "../GalleryOpenImage/GalleryOpenImage";
import { Link } from "react-router-dom";
import './GalleryContainer.css';
const ACTION = {
    openContainer   : "openContainer",
    closeContainer  : "closeContainer",
    changeCurrentImage      : "changeCurrentImage",
    changeCurrentImageIndex : "changeCurrentImageIndex",
    changeNextImageIndex    : "changeNextImage",
    resetImages : "resetImages",
    moveImagesLeft  : "moveImagesLeft",
    moveImagesRight : "moveImagesRight",
}
type openImageType = {
    openContainer:GalleryDataSingle|null,
    // imageIndex:number,
    currentImage : {
        state : "default"|"moveToLeft" | "moveToRight"
        index : number,

    }
    nextImage:{
        state : "hidden" | "moveFromLeft" | "moveFromRight"
        index : number,
    }
}
const reducer = (state, action:{type:string,data : any })=>{
    const out : openImageType = {...state};
    switch(action.type){
        case ACTION.openContainer           : out.openContainer = GalleryDataObj.getByTitle(action.data);  break
        case ACTION.closeContainer          : 
            out.currentImage.index = 0;
            out.openContainer  = null;            
            break;

        case ACTION.changeCurrentImageIndex   :
            out.currentImage.index = action.data;
            break;
        case ACTION.changeNextImageIndex    : 
            out.nextImage.index = action.data;   
            break;
        case ACTION.moveImagesLeft : 
            out.currentImage.state  = "moveToLeft";
            out.nextImage.state     = "moveFromRight";
            break;
        case ACTION.moveImagesRight : 
            out.currentImage.state  = "moveToRight";
            out.nextImage.state     = "moveFromLeft";
            break;
        case ACTION.resetImages : 
            out.currentImage.state = "default";
            out.nextImage.state = "hidden";
            out.nextImage.index = -1;
            break;
    }
    return out;
}
const GalleryContainer = ()=>{
    const [data] = useState <GalleryDataSingle[]>(GalleryDataArr);
    const [openImageData,dispatch] = useReducer(reducer,{
        openContainer: null,
        // imageIndex : 0, // Gets set to 0 when openContainer is changed
        currentImage:{
            state:"default",
            index:0,
        },
        nextImage : {
            state : "hidden",
            index : -1,
        }
    })
    const changePrompt = (setTo : string|null)=>{
        typeof(setTo) === "string" ? 
            dispatch({type:ACTION.openContainer,data:setTo}): 
            dispatch({type:ACTION.closeContainer,data:null});
        // dispatch({type:ACTION.changeCurrentImageIndex,data:0})
    }
    const changeImage = (increase:boolean)=>{
        const {currentIndex,nextIndex} = changeImageIndex(openImageData,increase)
        if (increase){
            dispatch({type:ACTION.moveImagesLeft,data:null})
        } else {
            dispatch({type:ACTION.moveImagesRight,data:null})
        }
        dispatch({type:ACTION.changeNextImageIndex,data:nextIndex});
        setTimeout(()=>{
            dispatch({type:ACTION.changeCurrentImageIndex,data:currentIndex});
            dispatch({type:ACTION.resetImages,data:null})
        },950)
        
    }
    const GalleryItems = data.map(item=>{
        const thumbnail = item.thumbnail;
        return <GalleryImgContainer changePrompt={changePrompt} 
            thumbnail={thumbnail} title={item.title}/>})
    return (
        <div className="galleryContainer">
            {GalleryItems} 
            <Link className="externalLink" to="https://www.instagram.com/sirenbeautynottingham/" target="_blank"><button className="content">See more</button></Link>
            {openImageData.openContainer !== null && <GalleryOpenImage state={openImageData.currentImage.state}
            galleryData={openImageData.openContainer} currentImage={openImageData.currentImage.index}
            changeImage={changeImage} closeFunc={changePrompt} controls/>}
            {/* Component that scrolls in */}
            {openImageData.openContainer !== null && openImageData.nextImage.index > -1 && <GalleryOpenImage 
            galleryData={openImageData.openContainer} currentImage={openImageData.nextImage.index}
            state={openImageData.nextImage.state}/>}
        </div>
    )
}

export default GalleryContainer;

const changeImageIndex = (imageData:openImageType,increase:boolean):{currentIndex:number,nextIndex:number}=>{
    const {openContainer,currentImage} = imageData;

    // Not sure why but just using the currentIndex works like a charm
    const length = openContainer !== null ? openContainer.images.length-1 : 0;
    const direction = increase ? 1 : -1;
    let currentIndex    = currentImage.index + direction; 
    if (currentIndex < 0)   currentIndex = length;  else if (currentIndex > length) currentIndex = 0;
    const nextIndex = currentIndex;
    return {currentIndex,nextIndex};
}