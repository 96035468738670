import './EmailInputStyle.css';
const EmailInput = (props:{required:boolean,title:string,type:string,className:string,inputType:string,stateUpdate:any,focusFunc:any})=>{
    const placeholder = props.required === true? "required" : "optional";
    const star = props.required === true ? "*" : "";

    return (
        <div className={props.className+" emailInput"}>
            <h5 >{props.title + star}</h5>
            {props.type === "text" ? 
                <input className=" input" onBlur={()=>{props.focusFunc(props.title,props.required)}} onChange={(e)=>{props.stateUpdate(e,props.title)}} type={props.inputType} name="" placeholder={placeholder}/> : 
                <textarea onChange={(e)=>{props.stateUpdate(e,props.title)}} className='input' placeholder={placeholder}/>}
        </div>
    )
}
export default EmailInput;