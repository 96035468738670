import EmailInput from "./EmailInput";
import './EmailForm.css';
import { useState } from "react";
const EmailTarget = "sirenbeautynottingham@gmail.com";
const CheckClassName = "inputCheck"
const EmailForm = (props:{showTitle:boolean})=>{
    const [email,setEmail] = useState({
        Email:"",
        Name:"",Subject:"",Message:""
    })
    const [classes,setClasses] = useState({
        Name : "", Email : ""
    })
    const checkRequired = (value:string,check:boolean)=>{
        if (check === true){ // Only runs on required inputs
            let emailProp = value as keyof typeof email;
            if (email[emailProp] === ""){
                let newClass = {...classes};
                let classProp = emailProp as keyof typeof classes;
                newClass[classProp] = CheckClassName;
                setClasses(newClass);
            } else {
                let newClass = {...classes};
                let classProp = emailProp as keyof typeof classes;
                newClass[classProp] = "";
                setClasses(newClass);
            }
        }
    }
    const emailChange = (e:any,property:any)=>{
        console.log(e.target.value);
        let newEmail ={...email};
        let propName = property as keyof typeof newEmail;
        newEmail[propName] = e.target.value;
        setEmail(newEmail);
    }
    const sendEmail = ()=>{
        const target:HTMLFormElement = document.querySelector('#emailForm') as HTMLFormElement;
        const emailTarget:any = document.getElementsByName('email')[0];
        const messageTarget:any = document.getElementsByName('text')[0];
        emailTarget.value = email.Email;
        messageTarget.value = `Name : ${email.Name}.`;
        if (email.Subject !== "") messageTarget.value += `Subject : ${email.Subject}.`;
        if (email.Message !== "") messageTarget.value += `Message : ${email.Message}.`;
        target.submit();
    }
    return(
        <form className="formElement" id="emailForm" action={`https://formsubmit.co/${EmailTarget}`} method="POST">
            <div style={{display:"none"}}>
                {/* Holds the values that the form uses */}
                <input name="text" id="trueTextValue"></input>
                <input name="email" id="trueEmailValue"></input>
                <input name="_captch" value="false"></input>
            </div>
            <div className="formCont">
                {props.showTitle === true ?<h4>Come Visit Us</h4> : <></>}
                <div className="topCont">
                    <EmailInput className={classes.Name} focusFunc={checkRequired} stateUpdate={emailChange} inputType="text" required={true} title="Name" type="text"/>
                    <EmailInput className={classes.Email} focusFunc={checkRequired} stateUpdate={emailChange} inputType="email" required={true} title="Email" type="text"/>
                </div>
                <EmailInput className="" focusFunc={checkRequired} stateUpdate={emailChange} inputType="text" required={false} title="Subject" type="text"/>
                <EmailInput className="" focusFunc={checkRequired} stateUpdate={emailChange} inputType="" required={false} title="Message" type="textArea"/>
                <div id="btnDiv">
                    <div id="btnDivInner" onClick={sendEmail}>
                        <h5>Submit</h5>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EmailForm