import { Helmet } from "react-helmet"
import PageMetaData from "../../Data/PageMetaData"
import { useState } from "react"

type props = {
    pageName : keyof typeof PageMetaData
}
const HeadData = (props:props)=>{
    const [data] = useState(PageMetaData[props.pageName]);
    const {title,description,keywords} = data;
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta name="keywords" content={keywords}/>
        </Helmet>
    )
}
export default HeadData;