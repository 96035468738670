import './FooterStyle.css';
import images from '../../Data/Images';
const Footer = ()=>{
    return (
        <footer className='footer'>
            <h4>Siren Ink Nottingham</h4>
            <h5>sirenbeautynottingham@gmail.com</h5>
            <div className='imageDiv'>
                <a rel='noreferrer' href="https://www.facebook.com/SirenBeautyNottingham" target="_blank"><img src={images.icons.facebook} alt="facebook link"/></a>
                <a rel='noreferrer' href="https://www.instagram.com/sirenbeautynottingham/" target="_blank"><img src={images.icons.instagram} alt="instagram link"/></a>
            </div>
            <h5>C2022 by Siren Ink Nottingham</h5>
        </footer>
    )
}

export default Footer;