const Title = "siren Ink |";
const PageMetaData = {
    home : {
        title : `${Title} Home`,
        description : `Located in Hucknall, Nottingham, siren Ink offers services of Powder/Ombre Brows, 
            Combination Brows, Lip Blush, Microneedling and Botched Ink removal.`,
        keywords : "Brows, lips, microblading, hukcnall, permanent makeup, lip blush, ombre brows, powderbrows"
    },
    services : {
        title : `${Title} Services`,
        description : `Take a look through the different services and see if they're for you`,
        keywords : "Brows, lips, microblading, hukcnall, permanent makeup, lip blush, ombre brows, powderbrows"
    },
    portfolio : {
        title : `${Title} Portfolio`,
        description : `Here is a collection of work that I have done.`,
        keywords : "Brows, lips, microblading, hukcnall, permanent makeup, lip blush, ombre brows, powderbrows"
    },
    booking : {
        title : `${Title} Booking`,
        description : `Please email for a free consultation and to set your appointment time.`,
        keywords : "Brows, lips, microblading, hukcnall, permanent makeup, lip blush, ombre brows, powderbrows"
    }
}
export default PageMetaData;