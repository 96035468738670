import Cards from './ImageData/Cards';
import Thumbnails from './ImageData/Gallery';
import Backgrounds from './ImageData/Backgrounds';
import Icons from './ImageData/Icons';
import Gallery from './ImageData/Gallery';


const images = {
    cards : {...Cards},
    thumbnails : {...Thumbnails},
    backgrounds:{...Backgrounds},
    icons:{...Icons},
    gallery:{...Gallery}
}

export default images;