import InfoSection from "./InfoSection"
import images from "../../../../Data/Images";
import './InfoStyle.css';
const Info = ()=>{
    return(
        <div className = "infoElement info" style={{
            // Had to make the style inline as the background style wasn't responding in scss yea
            background:`url(${images.backgrounds.contact1}) 100% 0%/ cover no-repeat #fafafa`,}}> 
            <div className="backgroundFilter"></div>
            <div className="content">
                <h3 className="header3Size">Contact</h3>
                <div className="infoContainer">
                    <InfoSection image="mail" title="Email" listItems={["sirenbeautynottingham@gmail.com"]}/>
                    <InfoSection image="phone" title="Phone - Text" listItems={["07743124969"]}/>
                    <InfoSection image="map" title="Address" listItems={['30a Annesley Road','Hucknall','Nottingham','NG15 7AB']}/>
                </div>
            </div>
        </div>
    )
}
export default Info;