import images from "../../../../Data/Images";
import './GalleryImgContainerStyle.css';
type props = {
    title : string
    thumbnail : {
        src : string
        alt : string
    }
    
    changePrompt : (setTo:string|null)=>void
}
const GalleryImgContainer = (props:props)=>{
    const {title,thumbnail, changePrompt} = props;
    let {alt,src} = thumbnail;

    src = typeof(thumbnail.src) === undefined ? "combo" : thumbnail.src;
    return (
        <div className="galleryImgContainer" onClick={()=>{changePrompt(title)}}>
            <div className="imgContainer">
                <img src={images.gallery.Thumbnails[src]} alt={alt} />
            </div>
            <div className="textContainer">
                <h5>{title}</h5>
            </div>
        </div>
    )
}

export default GalleryImgContainer;