import cardImgLip from '../../Assets/Images/cardImageLip.webp';
import cardImgComb from '../../Assets/Images/cardImageCombo.webp';
import cardImgMic from '../../Assets/Images/cardImageMicro.webp';
import cardImgOmb from '../../Assets/Images/cardImageOmbre.webp';
import cardImgMicN from '../../Assets/Images/cardImageMicroN.webp';
import cardImgRemo from '../../Assets/Images/cardImageRemoval.webp';
import cardImgRemo2 from '../../Assets/Images/cardImageRemoval2.webp';

const Cards = {
   combo   : cardImgComb,
   lip     : cardImgLip,
   micro   : cardImgMic,
   ombre   : cardImgOmb,
   microN  : cardImgMicN,
   remove  : cardImgRemo,
   remove2 : cardImgRemo2

}
export default Cards;