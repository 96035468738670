import thumbCombo from  '../../Assets/Images/Gallery/Thumbnails/Combo.webp';
import thumbEmerg from  '../../Assets/Images/Gallery/Thumbnails/emergencyRemove.webp';
import thumbLip from    '../../Assets/Images/Gallery/Thumbnails/Lip.webp';
import thumbMicro from  '../../Assets/Images/Gallery/Thumbnails/Micro.webp';
import thumbPowder from '../../Assets/Images/Gallery/Thumbnails/Powder.webp';
import thumbRemove from '../../Assets/Images/Gallery/Thumbnails/remove.webp';
import combo1 from '../../Assets/Images/Gallery/Content/Combo/1.jpg'
import combo2 from '../../Assets/Images/Gallery/Content/Combo/2.jpg'
import combo3 from '../../Assets/Images/Gallery/Content/Combo/3.jpg'
import eRemove1 from '../../Assets/Images/Gallery/Content/ERemove/1.jpg'
import eRemove2 from '../../Assets/Images/Gallery/Content/ERemove/2.jpg'
import eRemove3 from '../../Assets/Images/Gallery/Content/ERemove/3.jpg'
import lip1 from '../../Assets/Images/Gallery/Content/Lip/1.webp'
import lip2 from '../../Assets/Images/Gallery/Content/Lip/2.webp'
import lip3 from '../../Assets/Images/Gallery/Content/Lip/3.webp'
import micro1 from '../../Assets/Images/Gallery/Content/Micro/1.jpg'
import micro2 from '../../Assets/Images/Gallery/Content/Micro/2.jpg'
import micro3 from '../../Assets/Images/Gallery/Content/Micro/3.jpg'
import powder1 from '../../Assets/Images/Gallery/Content/Powder/1.webp'
import powder2 from '../../Assets/Images/Gallery/Content/Powder/2.webp'
import powder3 from '../../Assets/Images/Gallery/Content/Powder/3.webp'
import powder4 from '../../Assets/Images/Gallery/Content/Powder/4.webp'
import remove1 from '../../Assets/Images/Gallery/Content/Remove/1.jpg'
import remove2 from '../../Assets/Images/Gallery/Content/Remove/2.jpg'
import remove3 from '../../Assets/Images/Gallery/Content/Remove/3.jpg'

const Gallery = {
    Combo:[combo1,combo2,combo3],
    ERemove:[eRemove1,eRemove2,eRemove3],
    Lip:[lip1,lip2,lip3],
    Micro:[micro1,micro2,micro3],
    Powder:[powder1,powder2,powder3,powder4],
    Remove:[remove1,remove2,remove3],
    Thumbnails:{
        combo:          thumbCombo,
        emergencyRemove:thumbEmerg,
        lipBlush:       thumbLip,
        microNeedle:    thumbMicro,
        powder:         thumbPowder,
        removal:        thumbRemove
    }

}
export default Gallery;