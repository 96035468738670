import './App.css';
import Index from './Screens/home/Home';
import Services from './Screens/services/Services';
import Footer from './Components/Footer/Footer';
import Portfolio from './Screens/portfolio/Portfolio';
import Booking from './Screens/booking/Booking';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import './SharedStyle.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index/>
  },
  {
    path: "/services",
    element: <Services/>
  },
  {
    path: "/portfolio",
    element: <Portfolio/>
  },
  {
    path: "/booking",
    element: <Booking/>
  },
])


function App() {

  return (
    <>
    {/* <Navbar/> */}
    <RouterProvider router={router} />
    <Footer/>
    </>
  );
}

export default App;
