import navIconBook from     '../../Assets/Images/NavIcons/Book.png';
import navIconHome from     '../../Assets/Images/NavIcons/Home.png';
import navIconMail from     '../../Assets/Images/NavIcons/Mail.png';
import navIconMap from      '../../Assets/Images/NavIcons/Map.png';
import navIconPhone from    '../../Assets/Images/NavIcons/Phone.png';
import navIconPort from     '../../Assets/Images/NavIcons/Port.png';
import navIconServ from     '../../Assets/Images/NavIcons/Serv.png';
import iconFacebook from    '../../Assets/Images/facebook.png';
import iconTwitter from     '../../Assets/Images/twitter.png';
import iconInstagram from   '../../Assets/Images/instagram.png';
import iconProfile from     '../../Assets/Images/profileImage2.png';
import arrow from           '../../Assets/Images/NavIcons/Arrow.png'

const Icons = {
    arrrow      : arrow,
    booking     : navIconBook,
        home    : navIconHome,
        mail    : navIconMail,
        map     : navIconMap,
        phone   : navIconPhone,
        portfolio : navIconPort,
        service : navIconServ,
        facebook: iconFacebook,
        twitter : iconTwitter,
        instagram:iconInstagram,
        profile:iconProfile
}
export default Icons;