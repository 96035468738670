import EmailForm from "./EmailForm/EmailForm";
import Info from "./Info/Info";
import Map from './Map/Map';
import './ContactForm.css';

const ContactForm = ()=>{
    return (
        <div id="contactForm">
            <Info/>
            <EmailForm showTitle={true}/>
            <Map/>
        </div>
    )
}
export default ContactForm;