import './ServiceCardStyle.css';
import images from '../../../Data/Images';
import { useEffect, useState } from 'react';
const ServiceCard = (props:any)=>{
    const imgChose = (title:string)=>{
        let src : keyof typeof images.cards= "combo"; 
        const out : {src : string,alt:string} = {
            src : images[src],
            alt : ""
        }
        let name = (title.slice(0,3)).toLowerCase(); // Just goes off first 3 letters, think it makes it easier
        switch(name){
            case "pow" : 
                src = "ombre";    
                out.alt = "ombre brows application" 
                break;
            case "com" : 
                src = "combo";     
                out.alt = "combination brows application" 
                break;
            case "lip" : 
                src = "lip";       
                out.alt = "lip blush application" 
                break;
            case "mic" : 
                src = "microN";     
                out.alt = "microneedling application" 
                break;
            case "bot" : 
                // src = title.length > 20 ? "remove" : "remove2";
                // out.alt = title.length > 20 ? "remove" : "remove2";
                if (title.length > 20){
                    src = "remove"; out.alt = "Emmma providing botched ink removal";
                } else {
                    src = "remove2"; out.alt = "Emmma providing emergency ink removal";
                }
                break;
            default : console.log(name + " is not recognised, check ServiceCard.tsx");
        }
        out.src = images.cards[src];
        return out;
    }
    const widthCheck = ()=>{
        if ((window.screen.width >= 577)){
            setScreenSize("desk");
        } else 
        if ((window.screen.width < 576)){
            setScreenSize("mobile");
        }
    }
    const img = imgChose(props.title);
    const [screenSize,setScreenSize] = useState("desk");
    const [backgroundTarget,setBackgroundTarget] = useState({
        mob : "",desk:""
    })
    useEffect(()=>{
        widthCheck();
        window.addEventListener("resize",()=>{
            widthCheck();
        });
    },[])
    useEffect(()=>{
        screenSize !== "desk" ? setBackgroundTarget({mob:`url(${img.src})`,desk:``}) : setBackgroundTarget({mob:``,desk:img.src})
    },[screenSize])
    return(
        <div className="serviceCard" style={{backgroundImage:`${backgroundTarget.mob}`}}>
            <div className="contentCont container">
                <h3>{props.title}</h3>
                <h5><i>{props.price}</i></h5>
                <p>{props.description}</p>
            </div>
            <div className="imageCont container">
                <div className="innerContainer">
                    <div className="imageOverlay"></div>
                    <img className='imageInner' src={`${backgroundTarget.desk}`} alt={img.alt}/>
                </div>
            </div>
        </div>
    )
}

export default ServiceCard;