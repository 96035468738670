import images from "../../../Data/Images";
import './ProfileCardStyle.css';

type propType = {
    className : string,
    caption : string,
    imageAlt : string
}

const ProfileCard = (props:propType)=>{
    const {className,caption,imageAlt} = props;
    return (
        <div className={"profileCard "+className}>
            <p className="caption">{caption}</p>
            <img src={images.icons.profile} alt={imageAlt}/>
        </div>
    )
}
ProfileCard.defaultProps = {
    className : "",
    caption : "",
}
export default ProfileCard;