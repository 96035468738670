import { useEffect, useState } from "react";
import { GalleryDataSingle } from "../../../Data/GalleryData";
import images from "../../../Data/Images"
import './GalleryOpenImageStyle.css';
import { useFirstRender } from "../../../Data/CustomHooks";

type props = {
    closeFunc : (setTo:string|null)=>void | null
    changeImage : (increase:boolean)=>void | null
    galleryData : GalleryDataSingle
    currentImage : number
    controls : boolean
    state : "default" | "hidden" | "moveToLeft" | "moveToRight" | "moveFromLeft" | "moveFromRight"
    // subHeader : string
    // currentImage : number,
    // maxImage : number
}
const GalleryOpenImage = (props:props)=>{
    const {closeFunc,galleryData,currentImage,changeImage,controls,state} = props;
    const openClass = useFirstRender() ? "moveFromDown" : ""
    const [closeClass,setCloseClass] = useState<""|"close">("");
    const animationClass = state;
    const closeElement = ()=>{
        setCloseClass("close");
    }
    useEffect(()=>{
        if (closeClass ==="close"){
            setTimeout(()=>{
                closeFunc(null);
            },900) // Set to slightly less than the close animation
        }
    },[closeClass]); 
    return(
        <div className={`galleryOpenImage `}>
            {controls && <div className="behindContent" onClick={closeElement}></div>}
            
            <div className="galleryContent">
                <div className={`imageContainer ${animationClass} ${closeClass} ${openClass}`}>
                    <img src={galleryData.images[currentImage].src} alt=""/>
                </div>
                <div className="textContainer">
                    <h4>{galleryData.title}</h4>
                    {/* <h5>subhead pls</h5> */}
                    <span>{currentImage+1}/{galleryData.images.length}</span>

                    {controls && <div className="arrowsContainer">
                        <span className="arrowLeft" onMouseUp={()=>{changeImage(false)}}><img src={images.icons.arrrow}/></span>
                        <span onMouseUp={()=>{changeImage(true)}}><img src={images.icons.arrrow}/></span>
                    </div> }
                    
                </div>
            </div>

        </div>
    )
}

GalleryOpenImage.defaultProps = {
    controls : false,
    closeFunc : null,
    changeImage : null,
    side : null
}
export default GalleryOpenImage;